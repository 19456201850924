import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Card from "../UI/Card"

Privacy.propTypes = {
	className: PropTypes.string.isRequired,
}

function Privacy({ className }) {

	return (
		<Card className={className}>
			<h1>Privacy Policy</h1>
			
			<p>
				ZENPHARM LIMITED (“Zenpharm) has established this privacy policy to explain to you how your information is protected, collected, and used on its website zenpharm.co.uk (the “Site”), Zenpharm may update this policy at any time. Zenpharm will provide notice of materially significant changes to this privacy policy by posting notice on the Site.
			</p>
			
			

			<p>
				We collect information from your interaction with our Site, including computer and connection information, statistics on page views, traffic to and from the Site, demographic and navigational data, and standard weblog information. This information is exclusively obtained through Google Analytics.
			</p>

			<p>
				All data collected is anonymised. No names, addresses, nor other personal information about you is collected or stored.
			</p>
			
			<p>Any enquries regarding how we store and process information should be sent to <u><a href="mailto:hello@zenpharm.co.uk?subject=Data-Enquiry">hello@zenpharm.co.uk</a></u></p>

		</Card>
	)
}
export default styled(Privacy)`${({ theme })=>css`
	h1 {
		font-weight: 500;
	}
`}`